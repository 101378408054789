<template>
  <div class="main_container"
       v-if="assignDecompte!==undefined">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        <router-link to="/decompte">
          Décompte >
        </router-link>
        <span>Détails du décompte></span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Détails du décompte
          </div>
        </div>
      </div>
    </div>

    <div class="inner_container">
      <div class="row action_bar2">

        <div class="col-auto">
          <button type="button"
                  class="btn btn-f-blue "
                  @click="goBack()">&nbsp; &#x21E6; Retour
          </button>
        </div>

        <div class="col-auto">
          <button type="button"
                  class="btn btn-f-blue "
                  v-if="assignDecompte.statut =='DEMANDE_INFORMATION'"
                  @click="updateDecompteAgeroute()">&nbsp; &#9998; Modifier
          </button>
        </div>

        <div class="col-auto"
             v-if="(assignDecompte.dernier_valideur === null && assignDecompte.action_dernier_valideur ==='EN_ATTENTE') && assignDecompte.statut_imputeur_utilisateur =='non_imputer'">
          <button type="button"
                  class="btn btn-f-blue mx-1"
                  data-toggle="modal"
                  data-target="#suppressionModal"> &#10060; Supprimer
          </button>

        </div>
      </div>
    </div>

    <div class="col-auto">
      <div class="title_alert">
        <label v-if="assignDecompte.statut_final === 'VALIDE'"
               class="alert_success">Status : {{ assignDecompte.statut_final }} </label>
        <label v-else-if="assignDecompte.statut_final === 'EN_ATTENTE' && assignDecompte.action_dernier_valideur ==='DEMANDE_INFORMATION'"
               class="alert_danger">
          Statut : Demande information
        </label>
        <label v-else-if="assignDecompte.statut_final === 'EN_ATTENTE' && assignDecompte.action_dernier_valideur !=='DEMANDE_INFORMATION'"
               class="alert_danger">
          Statut : En attente de validation
        </label>
        <label v-else
               class="alert_yellow">
          Statut : {{ assignDecompte.statut_final }}
        </label>
        <!-- <span>
          <button v-if="viewTabCommentaire === 'false' && gettableauCommentaires.donnees !== undefined && gettableauCommentaires.donnees.length > 1"
                  class="btn btn-link"
                  type="button"
                  @click="displayTabCommentaire()">Voir l'historique</button>
          <button v-if="viewTabCommentaire === 'true'"
                  class="btn btn-link"
                  type="button"
                  @click="displayNoneTabCommentaire()">Cacher l'historique</button>
        </span> -->
      </div>
    </div>
    <div class="info_tab container"
         v-if="(gettableauCommentaires.donnees!== undefined && gettableauCommentaires.donnees[0] !== undefined) && viewTabCommentaire === 'true'">
      <h5>Demande d'informations</h5>
      <table class="table table-hover"
             style="font-size: 0.6em;">
        <thead>
          <tr scope="col"
              class="bg-black">
            <th>Origine</th>
            <th>Email</th>
            <th>Contenu</th>
            <th>Date de création</th>
          </tr>
        </thead>
        <tbody v-if="viewTabCommentaire === 'true'">
          <tr scope="row"
              v-for="(commentaire, commetaireKey) in gettableauCommentaires.donnees"
              :key="commetaireKey">
            <td>{{ commentaire.utilisateur.type_utilisateur }}</td>
            <td>{{ commentaire.utilisateur.email }}</td>
            <td>{{ commentaire.text }}</td>
            <td>{{ createDateFromString(commentaire.created_at) }}</td>
          </tr>
        </tbody>

        <tbody v-if="viewTabCommentaire === 'false'">
          <tr scope="row">
            <td>{{ gettableauCommentaires.donnees[0].utilisateur.type_utilisateur }}</td>
            <td>{{ gettableauCommentaires.donnees[0].utilisateur.email }}</td>
            <td>{{ gettableauCommentaires.donnees[0].text }}</td>
            <td>{{ createDateFromString(gettableauCommentaires.donnees[0].created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="info_tab container">
      <!-- affichage decompte travaux -->
      <div v-if="assignDecompte.caution_avance_demarrage"
           class="row mt-3">
        <table class="table col-md-10 mx-auto"
               style="font-size: 0.6em;">
          <thead>
            <tr class="bg-black">
              <th>Informations du décompte</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Type du décompte</td>
              <td>{{ assignDecompte.type_decompte }}</td>
            </tr>
            <tr>
              <td scope="row">Numéro du décompte</td>
              <td>{{ assignDecompte.numero_decompte }}</td>
            </tr>
            <tr>
              <td scope="row">Référence Ordre de service de prorogation de délai</td>
              <td>{{ assignDecompte.reference_ordre_service_prorogation_delai }}</td>
            </tr>
            <tr>
              <td scope="row">Date de service de prorogation de délai </td>
              <td>{{ assignDecompte.date_ordre_service_prorogation_delai }}</td>
            </tr>
            <tr>
              <td scope="row">Référence Ordre de service de modification d'itinéraire</td>
              <td>{{ assignDecompte.reference_ordre_service_modification_itineraire}}</td>
            </tr>
            <tr>
              <td scope="row">Date de l'Ordre de service de modification d'itinéraire </td>
              <td>{{ assignDecompte.date_service_modification_itineraire }}</td>
            </tr>
            <tr>
              <td scope="row">Désignation de l'assurance chantier </td>
              <td>{{ assignDecompte.libelle_assurance_chantier }}</td>
            </tr>
            <tr>
              <td scope="row">Montant de l'assurance chantier </td>
              <td>{{ assignDecompte.montant_assurance_chantier.toLocaleString() }}</td>
            </tr>
            <tr>
              <td scope="row">Date d'établissement de l'assurance chantier </td>
              <td>{{ assignDecompte.date_assurance_chantier }}</td>
            </tr>
            <tr>
              <td scope="row">Expiration de l'assurance chantier</td>
              <td>{{ assignDecompte.expiration_assurance_chantier_date}}</td>
            </tr>
            <tr>
              <td scope="row">Désignation du cautionnement AD</td>
              <td>{{ assignDecompte.libelle_cautionnement_ad}}</td>
            </tr>
            <tr>
              <td scope="row">Montant du cautionnement AD</td>
              <td>{{ assignDecompte.montant_cautionnement_ad.toLocaleString()}}</td>
            </tr>
            <tr>
              <td scope="row">Date d'établissement du cautionnement AD</td>
              <td>{{ assignDecompte.date_etablissement_cautionnement_ad}}</td>
            </tr>
            <tr>
              <td scope="row">Expiration de la caution AD</td>
              <td v-if="assignDecompte.expiration_caution_ad_date!=null">{{ assignDecompte.expiration_caution_ad_date}}</td>
              <td v-else>{{ assignDecompte.expiration_caution_ad_texte}}</td>
            </tr>
            <tr>
              <td scope="row">Désignation du cautionnement RG</td>
              <td>{{ assignDecompte.libelle_cautionnement_rg}}</td>
            </tr>
            <tr>
              <td scope="row">Montant du cautionnement RG</td>
              <td>{{ assignDecompte.montant_cautionnement_rg.toLocaleString()}}</td>
            </tr>
            <tr>
              <td scope="row">Date d'établissement du cautionnement RG</td>
              <td>{{ assignDecompte.date_etablissement_cautionnement_rg}}</td>
            </tr>
            <tr>
              <td scope="row">Expiration de la caution RG</td>
              <td v-if="assignDecompte.expiration_caution_rg_date!=null">{{ assignDecompte.expiration_caution_rg_date}}</td>
              <td v-else>{{ assignDecompte.expiration_caution_rg_texte}}</td>
            </tr>
            <tr>
              <td scope="row">Désignation du cautionnement définitif</td>
              <td>{{ assignDecompte.libelle_cautionnement_definitif}}</td>
            </tr>
            <tr>
              <td scope="row">Montant du cautionnement définitif</td>
              <td>{{ assignDecompte.montant_cautionnement_definitif.toLocaleString()}}</td>
            </tr>
            <tr>
              <td scope="row">Date d'établissement du cautionnement définitif</td>
              <td>{{ assignDecompte.date_etablissement_cautionnement_definitif}}</td>
            </tr>
            <tr>
              <td scope="row">Expiration de la caution définitif</td>
              <td v-if="assignDecompte.expiration_cautionnement_definitif_date!=null">{{ assignDecompte.expiration_cautionnement_definitif_date}}</td>
              <td v-else>{{ assignDecompte.expiration_cautionnement_definitif_texte}}</td>
            </tr>
            <tr>
              <td scope="row">Référence de la facture</td>
              <td>{{ assignDecompte.reference_facture }}</td>
            </tr>
            <tr>
              <td scope="row">Montant de la facture</td>
              <td>{{ Math.round(Number(assignDecompte.montant_decompte)).toLocaleString() }}</td>
            </tr>
            <tr>
              <td scope="row">Date de la facture</td>
              <td>{{ assignDecompte.date_facture }}</td>
            </tr>
            <tr>
              <td scope="row">Taux d'exécution physique</td>
              <td>{{ assignDecompte.taux_execution_physique }} %</td>
            </tr>
            <tr>
              <td scope="row">Date de visa de l'attachement</td>
              <td>{{ assignDecompte.date_visa_attachement }}</td>
            </tr>
            <tr>
              <td scope="row">Date de l'établissement de l'attachement</td>
              <td>{{ assignDecompte.date_attachement_constat_travaux }}</td>
            </tr>
            <tr>
              <td scope="row">Date de validation de l'attachement</td>
              <td>{{ assignDecompte.date_validation_attachement }}</td>
            </tr>
            <tr>
              <td scope="row">Montant du décompte</td>
              <td>{{ Math.round(assignDecompte.montant_facture_decompte).toLocaleString() }} FCFA</td>
            </tr>
            <tr>
              <td scope="row">Date du décompte</td>
              <td>{{ assignDecompte.date_dressage_decompte }}</td>
            </tr>
            <tr>
              <td scope="row">Date de validation du DR</td>
              <td>{{ assignDecompte.date_visa_dr_decompte }}</td>
            </tr>
            <tr>
              <td scope="row">Date de validation par le DMC</td>
              <td>{{ assignDecompte.date_visa_dmc_decompte }}</td>
            </tr>
            <tr>
              <td scope="row">Date de validation par le DGA</td>
              <td>{{ assignDecompte.date_visa_dga_decompte }}</td>
            </tr>
            <!-- <tr>
              <td scope="row">Date de fin des travaux</td>
              <td>{{ assignDecompte.date_fin_travaux }}</td>
            </tr> -->
            <tr>
              <td scope="row">Date d'établissement du procès verbal de reception des travaux</td>
              <td>{{ assignDecompte.date_etablissement_proces_verbal_reception_travaux }}</td>
            </tr>
            <tr>
              <td scope="row">Référence du certificat de service fait</td>
              <td>{{ assignDecompte.reference_certificat_service_fait }}</td>
            </tr>
            <tr>
              <td scope="row">Date d'établissement du certificat de service fait</td>
              <td>{{ assignDecompte.date_certificat_service_fait }}</td>
            </tr>
            
            
            <tr>
              <td scope="row">Montant décaissé</td>
              <td>{{ (Math.round(assignDecompte.montant_facture_decompte) - Math.round(restePayer)).toLocaleString() }} FCFA</td>
            </tr>
            <tr>
              <td scope="row">Reste du montant à décaisser</td>
              <td>{{ Math.round(restePayer).toLocaleString() }} FCFA</td>
            </tr>
            <tr>
              <td scope="row">Status</td>
              <td>
                <span v-if="assignDecompte.statut_final === 'EN_ATTENTE'"
                      class="badge badge-pill badge-warning">
                  En attente
                </span>
                <span v-if="assignDecompte.statut_final === 'VALIDE'"
                      class="badge badge-pill badge-success">
                  Validé
                </span>
                <span v-if="assignDecompte.statut_final === 'DEMANDE_INFORMATION'"
                      class="badge badge-pill badge-danger">
                  Rejeté
                </span>
                <button v-if="viewTabCommentaire === 'false'"
                        class="ml-2 btn btn-link"
                        type="button"
                        @click="displayTabCommentaire()">Voir les commentaires</button>
                <button v-if="viewTabCommentaire === 'true'"
                        class="ml-2 btn btn-link"
                        type="button"
                        @click="displayNoneTabCommentaire()">Cacher les commentaires</button>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr class="bg-black">
              <th>Source du décompte</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Programme</td>
              <td>{{ marche.activite.rubrique.programme.libelle }}</td>
            </tr>
            <tr>
              <td scope="row">Rubrique</td>
              <td>{{ marche.activite.rubrique.libelle }}</td>
            </tr>
            <tr>
              <td scope="row">Activité</td>
              <td>{{ assignDecompte.activite.libelle }}</td>
            </tr>
            <tr>
              <td scope="row">Marché</td>
              <td>{{ marche.numero_marche }}</td>
            </tr>
            <tr>
              <td scope="row">Département</td>
              <td>{{ marche.departement }}</td>
            </tr>
            <tr>
              <td scope="row">Lot</td>
              <td>{{ marche.lot }}</td>
            </tr>
            <tr>
              <td scope="row">Date du démarrage effectif</td>
              <td>{{ marche.date_demarrage_effectif }}</td>
            </tr>
            <tr>
              <td scope="row">Date de fin des travaux</td>
              <td>{{ marche.date_fin_travaux }}</td>
            </tr>
          </tbody>
          <thead>
            <tr class="bg-black">
              <th style="width:40%">Fichier à télécharger</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Caution d'avance de démarrage</td>
              <td>
                <a :href="assignDecompte.caution_avance_demarrage"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Cautionnement définitif</td>
              <td>
                <a :href="assignDecompte.cautionnement_definitif"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Facture</td>
              <td>
                <a :href="assignDecompte.facture"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Situation financière du marché</td>
              <td>
                <a :href="assignDecompte.situation_financiere_marche"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Retenue de garantie</td>
              <td>
                <a :href="assignDecompte.retenue_garantie"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Attachement</td>
              <td>
                <a :href="assignDecompte.attachement"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Décompte</td>
              <td>
                <a :href="assignDecompte.decompte"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Procès verbal de réception des travaux</td>
              <td>
                <a :href="assignDecompte.proces_verbal_reception_travaux"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Entreprise</td>
              <td>
                <a :href="assignDecompte.entreprise"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Ordre de service de démarrage</td>
              <td>
                <a :href="assignDecompte.ordre_service_demarrage"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Ordre de service d'arrêt</td>
              <td>
                <a :href="assignDecompte.ordre_service_arret"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Ordre de service de reprise des travaux</td>
              <td>
                <a :href="assignDecompte.ordre_service_reprise_travaux"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Ordre de service de prorogation de délai</td>
              <td>
                <a :href="assignDecompte.ordre_service_reprise_prorogation_delai"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Ordre de service de modification d'itinéraire</td>
              <td>
                <a :href="assignDecompte.ordre_service_modification_itineraire"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Assurances chantier</td>
              <td>
                <a :href="assignDecompte.assurance_chantier"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <!-- Fin decompte travaux -->
      <!-- affichage decompte prestation -->
      <div v-else-if="assignDecompte.rapport"
           class="row mt-3">
        <table class="table col-md-10 mx-auto"
               style="font-size: 0.6em;">
          <thead>
            <tr class="bg-black">
              <th>Informations du décompte</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Type du décompte</td>
              <td>{{ assignDecompte.type_decompte }}</td>
            </tr>
            <tr>
              <td scope="row">Numéro du décompte</td>
              <td>{{ assignDecompte.numero_decompte }}</td>
            </tr>
            <tr>
              <td scope="row">Objet des travaux</td>
              <td>{{ assignDecompte.objet_travaux }}</td>
            </tr>
            <tr>
              <td scope="row">Référence de la facture</td>
              <td>{{ assignDecompte.reference_facture }}</td>
            </tr>
            <tr>
              <td scope="row">Référence du certificat de service fait</td>
              <td>{{ assignDecompte.reference_certificat_service_fait }}</td>
            </tr>
            <tr>
              <td scope="row">Référence du certificat de paiement</td>
              <td>{{ assignDecompte.reference_certificat_paiement }}</td>
            </tr>
            <tr>
              <td scope="row">Référence du courrier d'appel de fond</td>
              <td>{{ assignDecompte.reference_courrier_appel_fonds }}</td>
            </tr>
            <tr>
              <td scope="row">Référence du courrier de demande de proposition de prix</td>
              <td>{{ assignDecompte.reference_courrier_demande_proposition_prix }}</td>
            </tr>
            <tr>
              <td scope="row">Référence du courrier d'indication de la ligne supportant les dépenses</td>
              <td>{{ assignDecompte.reference_courrier_indication_ligne_supportant_depense }}</td>
            </tr>
            <tr>
              <td scope="row">Référence du courrier d'invitation à une séance de négociation</td>
              <td>{{ assignDecompte.reference_courrier_invitation_seance_negociation }}</td>
            </tr>
            <tr>
              <td scope="row">Référence de la demande de cotation</td>
              <td>{{ assignDecompte.reference_demande_cotation }}</td>
            </tr>
            <tr>
              <td scope="row">date d'établissement du certificat de service fait</td>
              <td>{{ assignDecompte.date_etablissement_certificat_service_fait }}</td>
            </tr>
            <tr>
              <td scope="row">date d'établissement du certificat de paiement</td>
              <td>{{ assignDecompte.date_etablissement_certificat_pour_paiement }}</td>
            </tr>
            <tr>
              <td scope="row">date d'établissement du courrier d'appel de fond</td>
              <td>{{ assignDecompte.date_etablissement_courrier_appel_fonds }}</td>
            </tr>
            <tr>
              <td scope="row">date d'établissement du courrier de demande de proposition de prix</td>
              <td>{{ assignDecompte.date_etablissement_courrier_demande_proposition_prix }}</td>
            </tr>
            <tr>
              <td scope="row">date d'établissement du courrier d'indication de la ligne supportant les dépenses</td>
              <td>{{ assignDecompte.date_etablissement_courrier_indication_ligne_supportant_depense }}</td>
            </tr>
            <tr>
              <td scope="row">date d'établissement du courrier d'invitation à une séance de négociation</td>
              <td>{{ assignDecompte.date_etablissement_courrier_invitation_seance_negociation }}</td>
            </tr>
            <tr>
              <td scope="row">date d'établissement de la feuille de temps</td>
              <td>{{ assignDecompte.date_etablissement_feuille_temps }}</td>
            </tr>
            <tr>
              <td scope="row">Montant du décompte</td>
              <td>{{ Math.round(assignDecompte.montant_facture_decompte).toLocaleString() }} FCFA</td>
            </tr>
            <tr>
              <td scope="row">Date décompte</td>
              <td>{{ assignDecompte.date_facture }}</td>
            </tr>
            <tr>
              <td scope="row">Montant de la facture</td>
              <td>{{ Math.round(assignDecompte.montant_decompte).toLocaleString() }} FCFA</td>
            </tr>
            <tr>
              <td scope="row">Date facture</td>
              <td>{{ assignDecompte.date_decompte }}</td>
            </tr>
            <tr>
              <td scope="row">Date d'approbation</td>
              <td>{{ assignDecompte.date_approbation }}</td>
            </tr>
            <tr>
              <td scope="row">Date de début d'exécution</td>
              <td>{{ assignDecompte.date_debut_execution }}</td>
            </tr>
            <tr>
              <td scope="row">Date de fin d'exécution</td>
              <td>{{ assignDecompte.date_fin_execution }}</td>
            </tr>
            <tr>
              <td scope="row">Status</td>
              <td>
                <span v-if="assignDecompte.statut_final === 'EN_ATTENTE'"
                      class="badge badge-pill badge-warning">
                  En attente
                </span>
                <span v-if="assignDecompte.statut_final === 'VALIDE'"
                      class="badge badge-pill badge-success">
                  Validé
                </span>
                <span v-if="assignDecompte.statut_final === 'DEMANDE_INFORMATION'"
                      class="badge badge-pill badge-danger">
                  Rejeté
                </span>
                <button v-if="viewTabCommentaire === 'false'"
                        class="ml-2 btn btn-link"
                        type="button"
                        @click="displayTabCommentaire()">Voir les commentaires</button>
                <button v-if="viewTabCommentaire === 'true'"
                        class="ml-2 btn btn-link"
                        type="button"
                        @click="displayNoneTabCommentaire()">Cacher les commentaires</button>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr class="bg-black">
              <th>Source du décompte</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Programme</td>
              <td>{{ marche.activite.rubrique.programme.libelle }}</td>
            </tr>
            <tr>
              <td scope="row">Rubrique</td>
              <td>{{ marche.activite.rubrique.libelle }}</td>
            </tr>
            <tr>
              <td scope="row">Activité</td>
              <td>{{ assignDecompte.activite.libelle }}</td>
            </tr>
            <tr>
              <td scope="row">Marché</td>
              <td>{{ marche.numero_marche }}</td>
            </tr>
            <tr>
              <td scope="row">Département</td>
              <td>{{ marche.departement }}</td>
            </tr>
            <tr>
              <td scope="row">Lot</td>
              <td>{{ marche.lot }}</td>
            </tr>
            <tr>
              <td scope="row">Date du démarrage effectif</td>
              <td>{{ marche.date_demarrage_effectif }}</td>
            </tr>
            <tr>
              <td scope="row">Date de fin des travaux</td>
              <td>{{ marche.date_fin_travaux }}</td>
            </tr>
          </tbody>
          <thead>
            <tr class="bg-black">
              <th style="width:40%">Fichier à télécharger</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Facture</td>
              <td>
                <a :href="assignDecompte.facture"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Certificat de service fait</td>
              <td>
                <a :href="assignDecompte.certificat_service_fait"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Attachement</td>
              <td>
                <a :href="assignDecompte.attachement"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Décompte</td>
              <td>
                <a :href="assignDecompte.decompte"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Rapport</td>
              <td>
                <a :href="assignDecompte.rapport"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">courrier de confirmation deprix</td>
              <td>
                <a :href="assignDecompte.courrier_confirmation_prix"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Courrier d'indication de ligne supportant la dépense</td>
              <td>
                <a :href="assignDecompte.courrier_indication_ligne_lcd"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Courrier d'invitation à la séance de négociation</td>
              <td>
                <a :href="assignDecompte.courrier_invitation_sn"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Feuille de temps</td>
              <td>
                <a :href="assignDecompte.feuille_temps"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Formulaire de demande de cotation</td>
              <td>
                <a :href="assignDecompte.formulaire_demande_cotation"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Formulaire de sélection</td>
              <td>
                <a :href="assignDecompte.formulaire_selection"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal2"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-uppercase"
                id="exampleModalLabel">Demande d'informations</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p v-if="assignDecompte.demande_information"
               class="mt-2">{{ assignDecompte.demande_information }}</p>
          </div>
        </div>
      </div>
    </div>
    
    <!-- MODAL SUPPRESSION-->
    <div class="modal fade"
         id="suppressionModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="suppressionModalLabel"
         aria-hidden="true">

      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"
                id="suppressionModalLabel">Suppression de decompte</h4>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <p class="text-danger">Voulez vous vraiment supprimer ce decompte ?</p>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-success"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    @click="SupprimerDecompteAGEROUTE(assignDecompte.numero_decompte)"
                    class="btn btn-danger">Supprimer</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<style>
@import "./decompte.css";

</style>
<script>
import { mapActions, mapGetters } from "vuex"
import Notif from "@/components/shared/Toast"
export default {
  name: "ModifyDecompte",
  components:{
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    programmeData: {
      limite: 10,
      avant: "MQ=="
    },
    viewTabCommentaire: "false",
    status_valider:"false"
  }),
  watch: {
    ["form.data"]: {
      deep: true,
      immediate: false,
      handler: "onFormChange"
      
    },
    assignDecompte(){
      this.getMarche(this.assignDecompte.marche.id)
    },
    marche(){
      if (this.marche) {        
        var nouveau_departement=""
        for (let index = 0; index < this.marche.departement.length; index++) {
          if (index<this.marche.departement.length && index+1<this.marche.departement.length) {
            nouveau_departement+=this.marche.departement[index].libelle+" - "    
          }else{
            nouveau_departement+=this.marche.departement[index].libelle    
          }
        }
        this.marche.departement=nouveau_departement
      }
    },
  },
  computed: {
    ...mapGetters(["decompterrors", "successdecompte", "marches", "files", "prestataires", "assignDecompte", "gettableauCommentaires","restePayer","marche","getEtatSuppressionDecompte"])
  },
  created() {
    if (this.$route.params.id) {
      this.getDecompte(this.$route.params.id)
      this.resteAPayer(this.$route.params.id)
    } else {
      this.noData = "No record"
    }
    this.actionGetCommentaires(this.$route.params.id)
  },
  methods: {
    ...mapActions(["saveDecomptes", "Marches", "saveFile", "getPrestataires", "getDecompte","resteAPayer","getMarche", "actionGetCommentaires","actionSuppresionDecompte"]),
    displayTabCommentaire() {
      this.viewTabCommentaire = "true"
    },
    displayNoneTabCommentaire() {
      this.viewTabCommentaire = "false"
    },
    createDateFromString(chaine) {
      var tab = chaine.split(":")
      var dateheure = tab[0].split("T")
      var date = dateheure[0]
      var heureminute = dateheure[1] + "h" + tab[1]
      //var seconde = tab[2].split(".")[0]
      return date + " à " + heureminute
    },
    /**
     * Permet de supprimer un decompte dont le numero est donné en argument
     * @param decompteId
     * @constructor
     */
    SupprimerDecompteAGEROUTE(decompteId){
      try {
        this.actionSuppresionDecompte(this.$route.params.id)
      }catch (exception){

      }

      if(this.getEtatSuppressionDecompte ===200){
        this.notif.message = "Le decompte avec numero = "+decompteId+" a été bien supprimé"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"

        setTimeout(
          function() {
            this.notif.activated = ""
            this.$router.push({name:"decompte"})
            location.reload()
          }.bind(this),3000)
      }else if(this.getEtatSuppressionDecompte ===500 || this.getEtatSuppressionDecompte ===404){
        this.notif.message = "Le decompte avec numero = "+decompteId+" n'a pas été supprimé"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"

        setTimeout(
          function() {
            this.notif.activated = ""
            this.$router.push({name:"decompte"})
            location.reload()
          }.bind(this),3000)
      }
    },
    /**
     * Fait un retour en arriere dans la navigation ( ramene sur la liste des decomptes)
     */
    goBack(){
      this.$router.push({name:"decompte"})
    },
    /**
     * Redirige vers la page de modification d"un decompte ageroute
     */
    updateDecompteAgeroute(){
      if (this.assignDecompte.type_decompte=="decompte_travaux_ageroute") {
        this.$router.push({name:"UpdateDecompte", params:{id:this.$route.params.id}})
      }else{
        this.$router.push({name:"UpdateDecomptePrestation", params:{id:this.$route.params.id}})

      }
    }
  }
}
</script>
